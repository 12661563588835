:root {
--ftc: 0px;
}
.fs-8{
font-size: calc(8px + var(--ftc));
}
.fs-10{
font-size: calc(10px + var(--ftc));
}

.fs-12{
font-size: calc(12px + var(--ftc));
}
.fs-13{
font-size: calc(13px + var(--ftc));
}
.fs-14{
font-size: calc(14px + var(--ftc));
}
.fs-15{
font-size: calc(15px + var(--ftc));
}
.fs-16{
font-size: calc(16px + var(--ftc));
}
.fs-18{
font-size: calc(18px + var(--ftc));
}
.fs-20{
font-size: calc(20px + var(--ftc));
}
.fs-22{
font-size: calc(22px + var(--ftc));
}
.fs-24{
font-size: calc(24px + var(--ftc));
}
.fs-26{
font-size: calc(26px + var(--ftc));
}
.fs-28{
font-size: calc(28px + var(--ftc));
}
.fs-32{
font-size: calc(32px + var(--ftc));
}
.fs-36{
font-size: calc(36px + var(--ftc));
}
.fs-40{
font-size: calc(40px + var(--ftc));
}
.fs-50{
font-size: calc(40px + var(--ftc));
}
.fw-5{
font-weight: 500;
}
.fw-6{
font-weight: 600;
}
.tc-1{
    color: var(--font, #061126);
}
.tc-2{
    color: var(--font, #31A7DE);
}
.tc-3{
    color: #161616;
}
.tc-4{
    color: #2DC76D;
}
.tc-5{
    color: #FF731D;
}

.tc-6{
    color: #4C4E64;
}
.tc-7{
    color: rgba(76, 78, 100, 0.87);
}
.tc-8{
    color: #ff6b00;
}
.tc-9{
    color: #0760FC;
}
.tc-10{
    color: rgba(76, 78, 100, 0.68);
}
.tc-11{
    color:#6D788D;
}
.tc-12{
    color: rgba(76, 78, 100, 0.50);
}
.tc-13{
    color: #A5A7B1;
}
.tc-14{
    color: #767676;
}
.tc-15{
    color: #565DFF;
}
.fw-500{
    font-weight: 500;
}