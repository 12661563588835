.global-modal .modal-icon {
    object-fit: contain;
    width: 84px;
    aspect-ratio: 1;
}

.global-modal .modal-btn {
    color: #FFF;
    text-align: center;
    font-family: Sarabun;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    border-radius: 8px;
    background:  #FF6B00;
    padding: 10px 35px;
    border:1px solid #FF6B00;
}