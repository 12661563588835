.document-page .cancel-warn {
  min-width: 32.6vw;
  width: fit-content;
  padding: 0.8vw 1vw;
  border-radius: 0.4vw;
  background-color: rgba(249, 80, 87, 0.1);
  color: #f95057;
  display: flex;
  align-items: center;
  gap: 0.6vw;
}
.document-page .cancel-warn img {
  aspect-ratio: 1;
  width: 1vw;
  height: 100%;
  object-fit: contain;
}
.document-page .edit-warn {
  min-width: 32.6vw;
  width: fit-content;
  padding: 0.8vw 1vw;
  border-radius: 0.4vw;
  background-color: rgba(255, 107, 0, 0.14);
  color: #ff731d;
  display: flex;
  align-items: center;
  gap: 0.6vw;
}

.document-page .edit-warn img {
  aspect-ratio: 1;
  width: 1vw;
  height: 100%;
  object-fit: contain;
}
.document-page .force-success-warn {
  min-width: 32.6vw;
  width: fit-content;
  padding: 0.8vw 1vw;
  border-radius: 0.4vw;
  background-color: rgba(45, 199, 109, 0.1);
  color: #2dc76d;
  display: flex;
  align-items: center;
  gap: 0.6vw;
}

.document-page .force-success-warn img {
  aspect-ratio: 1;
  width: 1vw;
  height: 100%;
  object-fit: contain;
}
