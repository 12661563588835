@tailwind base;
@tailwind components;
@tailwind utilities;

.pageprint td,.pageprint th{
    padding: 5px;
    border-color: #909090 !important;
    color: #000;

}

.ellipsis{
    text-overflow: ellipsis;
    overflow: hidden;

    white-space: nowrap;
}

.table-print td,.table-print th{
    padding: 6px 8px;
}