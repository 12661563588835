.global-loader-overlay {
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(255,255,255,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
}